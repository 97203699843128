import { ActivityDayDetails, ActivityNbDayDetails } from '@bloomays-lib/types.shared';
import { dateFormat, isHoliday, isSaturday, isSunday, isWeekEndDay, lastDateOfMonth, weekDay } from './date.utils';

export const retrieveActivityDetailValue = (
  activity: ActivityNbDayDetails,
  day: number,
  property: 'isAstreinte' | 'duration' | 'comment',
): number | undefined | string => {
  if (activity === undefined) return;
  const objectLength = Object.keys(activity).length;
  if (objectLength === 0) return;
  switch (typeof activity[day]) {
    case 'number':
    case 'undefined':
    case null:
      switch (property) {
        case 'duration':
          // legacy format
          return (activity[day] || 0) as unknown as number;
        case 'comment':
          return '';
        case 'isAstreinte':
          return 0;
      }
      break;
  }
  return activity[day]?.[property] ? activity[day][property] : property === 'comment' ? '' : 0;
};

export const convertActivityToEachDaysArray = (month: string, activityDaysDetails: string): ActivityDayDetails[] => {
  let activityNbDaysDetails: ActivityNbDayDetails;
  try {
    activityNbDaysDetails = JSON.parse(activityDaysDetails);
  } catch (err: any) {
    throw new Error(`Activity is not a valid JSON - Activity: ${activityDaysDetails}`, {
      cause: err,
    });
  }

  const lastDateCurrentMonth = lastDateOfMonth(new Date(month));

  const arrayDateofEachDay: ActivityDayDetails[] = [];
  for (let i = lastDateCurrentMonth.getDate(); i >= 1; i--) {
    const journey = `${lastDateCurrentMonth.getMonth() + 1}/${i}/${lastDateCurrentMonth.getFullYear()}`;

    const journeyStringToDate = new Date(journey);
    const date = dateFormat(journeyStringToDate, 'dd/MM/yyyy', 'fr');

    arrayDateofEachDay.push({
      id: i,
      day: weekDay[journeyStringToDate.getDay()],
      date: date,
      isHoliday: isHoliday(journeyStringToDate),
      isWeekEndDay: isWeekEndDay(journeyStringToDate),
      isSaturday: isSaturday(journeyStringToDate),
      isSunday: isSunday(journeyStringToDate),
      dayWorked: (retrieveActivityDetailValue(activityNbDaysDetails, i, 'duration') as number) || 0,
      checked: false,
      comment: (retrieveActivityDetailValue(activityNbDaysDetails, i, 'comment') as string) || '',
      astreinte: (retrieveActivityDetailValue(activityNbDaysDetails, i, 'isAstreinte') as number) || 0,
    });
  }

  return arrayDateofEachDay.reverse();
};

export const createDaysDetail = (days: ActivityDayDetails[]): ActivityNbDayDetails => {
  if (!days || days.length === 0) {
    throw new Error('Pas de détail du CRA');
  }
  const details: ActivityNbDayDetails = {};

  days.forEach((day: ActivityDayDetails) => {
    details[day.id] = {
      duration: day.dayWorked || 0,
      comment: day.comment || '',
      isAstreinte: day.astreinte || 0,
    };
  });
  return details;
};
