import { TitlePart } from '@bloomays-lib/ui.shared';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import DetailsSectionCard from '../molecules/DetailsSectionCard';
import { Button } from '@bloomays-lib/ui.shared';
import { styled } from '@mui/material/styles';
import { months } from '../../helpers/CRA';
import { useEffect, useState } from 'react';
import { LabelAndInput } from '../molecules/LabelAndInput';
import { fixed } from '@bloomays-lib/utils.shared';

export type SummaryDaysWorkedProps = {
  month: string;
  daysWorked: number;
  daysAstreinte: number;
  daysMarkup: number;
  daysMarkup2: number;
  submitCRA?: () => Promise<void>;
  handleClose?: () => void;
};

const SummaryDaysWorked = ({
  month,
  daysWorked,
  daysAstreinte,
  daysMarkup,
  daysMarkup2,
  submitCRA,
  handleClose,
}: SummaryDaysWorkedProps): JSX.Element => {
  const [loadingButtonSubmit, setLoadingButtonSubmit] = useState<boolean>(false);
  const [hasMarkup, setHasMarkup] = useState<boolean>(daysMarkup > 0 || daysMarkup2 > 0 || daysAstreinte > 0);
  const [checked, SetChecked] = useState<boolean>(!hasMarkup);
  useEffect(() => {
    setHasMarkup(daysMarkup > 0 || daysMarkup2 > 0 || daysAstreinte > 0);
  }, [daysMarkup, daysMarkup2, daysAstreinte]);
  const date = new Date(month);
  const monthNumber = date.getMonth();

  const y = date.getFullYear();
  const m = months[monthNumber];

  const total = fixed(daysWorked + daysMarkup + daysMarkup2 + daysAstreinte);
  const wordDay = total !== 0 ? 'jours' : 'jour';

  const wordDayWeek = fixed(daysWorked, 3) <= 1 ? 'jour' : 'jours';
  const wordDayMarkup = fixed(daysMarkup, 3) <= 1 ? 'jour' : 'jours';
  const wordDayMarkup2 = fixed(daysMarkup2, 3) <= 1 ? 'jour' : 'jours';
  const wordDayAstreinte = fixed(daysAstreinte, 3) <= 1 ? 'jour' : 'jours';
  const onSubmitCRA = () => {
    setTimeout(() => {
      setLoadingButtonSubmit(true);
    }, 0);
    if (!submitCRA) return;
    void submitCRA().finally(() => {
      setLoadingButtonSubmit(false);
    });
  };

  return (
    <div>
      <ContainerSummary>
        <TitlePart
          textTitle="Récapitulatif des jours travaillés"
          role={'banner'}
          ariaLabel={'Récapitulatif des jours travaillés'}
        />
        <DateSingleLineText text={`${m} ${y}`} />
      </ContainerSummary>
      <DetailsSectionCard
        title="Jour de semaine"
        details="Du lundi au vendredi en heures ouvrées "
        text={`${daysWorked} ${wordDayWeek}`}
      />
      {hasMarkup && (
        <>
          <DetailsSectionCard title="Samedi" details="En heures ouvrées" text={`${daysMarkup} ${wordDayMarkup}`} />
          <DetailsSectionCard title="Dimanche et Férié" text={`${daysMarkup2} ${wordDayMarkup2}`} />
          <DetailsSectionCard title="Heures sup (HNO) ou astreintes" text={`${daysAstreinte} ${wordDayAstreinte}`} />
          <SingleLineText text={`Total: ${total} ${wordDay}`} variant="body2Medium" />
          {submitCRA && (
            <LabelAndInput
              name={'confirmAll'}
              textLabel={
                'Je confirme que les jours travaillés hors semaine et les astreintes nécessitent une validation du client.'
              }
              type={'checkbox'}
              checked={checked}
              handleChange={(e) => {
                SetChecked(e.target.checked);
              }}
              id={'confirmAll'}
              readOnly={false}
            />
          )}
        </>
      )}
      {total === 0 && <SingleLineText text="Tu n'as pas travaillé ce mois-ci." />}
      <ContainerButton>
        {submitCRA && (
          <Button
            textButton="Valider"
            onClick={onSubmitCRA}
            loading={loadingButtonSubmit}
            disable={!checked}
            role="button"
            ariaLabel="Valider le total"
            id="ValidateTotal"
          />
        )}
        {handleClose && <Button textButton="Modifier la saisie" onClick={() => handleClose()} color="secondary" />}
      </ContainerButton>
    </div>
  );
};

export default SummaryDaysWorked;
export { SummaryDaysWorked };

const ContainerSummary = styled('div')(() => ({
  textAlign: 'left',
}));

const ContainerButton = styled('div')(({ theme }) => ({
  margin: '2em auto',
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'space-around',
}));

const DateSingleLineText = styled(SingleLineText)(({ theme }) => ({
  color: theme.palette.grey[500],
}));
