import { useEffect, useState } from 'react';
import { LoaderSkeleton, TitlePart } from '@bloomays-lib/ui.shared';
import { Button, SingleLineText } from '@bloomays-lib/ui.shared';
import { ActivityDayDetails, ActivityDayType } from '@bloomays-lib/types.shared';
import SummaryDaysWorked from './SummaryDaysWorked';
import Modal from '../atoms/Modal';
import { calculPerDay } from '../../helpers/CRA';
import { FormCRA } from '../molecules/FormCRA';

export type AddCRAProps = {
  eachDay: ActivityDayDetails[];
  submitCRA: (e: ActivityDayDetails[]) => Promise<void>;
  month: string;
  hoursPerDay: number;
};

const AddCRA = ({ eachDay, month, submitCRA, hoursPerDay }: AddCRAProps) => {
  const [tableData, setTableData] = useState(eachDay);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (eachDay) {
      setTableData(eachDay);
    }
  }, [eachDay]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        additionnalCSS={{
          overflowY: 'auto',
          width: '65%',
          padding: '32px',
          border: 'none',
          borderRadius: '20px',
        }}
      >
        <SummaryDaysWorked
          month={month}
          daysWorked={calculPerDay(tableData, ActivityDayType.nbDaysWorked)}
          daysMarkup={calculPerDay(tableData, ActivityDayType.nbDaysMarkupWorked)}
          daysMarkup2={calculPerDay(tableData, ActivityDayType.nbDaysMarkup2Worked)}
          daysAstreinte={calculPerDay(tableData, ActivityDayType.astreinte)}
          submitCRA={() => {
            return submitCRA(tableData);
          }}
          handleClose={handleClose}
        />
      </Modal>
      <TitlePart textTitle="Ajouter mes CRA" />
      <div style={{ margin: '1.5em 0' }}>
        <SingleLineText text={`Une journée correspond à ${hoursPerDay}h de travail`} />
        <SingleLineText text="Pour aller plus vite, tu peux tout sélectionner (grâce à la box 'Tout sélectionner') ou sélectionner les jours de ton choix et leur attribuer une valeur ! :)" />
        <SingleLineText text="Samedi : Majoration à 150% *, Dimanche, férié et heures sup (HNO) : Majoration à 200% *" />
        <SingleLineText text="* sauf si contractuellement différent" />
      </div>

      {tableData?.length === 0 ? (
        <LoaderSkeleton height={1000} width={1500} />
      ) : (
        <>
          <FormCRA
            eachDays={tableData}
            hoursPerDay={hoursPerDay}
            onCRAUpdate={(cras) => {
              setTableData(cras);
            }}
          />
          <Button
            sx={{ marginTop: '1em' }}
            textButton={'Valider !'}
            onClick={() => handleOpen()}
            role="button"
            ariaLabel="Valider"
          />
        </>
      )}
    </div>
  );
};

export default AddCRA;
export { AddCRA };
